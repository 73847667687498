<template>
  <div class="page-classroom-edit">
    <div class="my-page">
      <van-form @submit="save" style="background-color: transparent">
        <div class="my-card">
          <van-field
            readonly
            required
            v-model="viewData.BeginDate"
            name="BeginDate"
            label="加班开始时间"
            placeholder="加班开始时间"
            :rules="[{ required: true, message: '请选择加班开始时间' }]"
            @click="showTime = true"
          />
          <van-popup v-model="showTime" position="bottom">
            <van-datetime-picker
              type="datetime"
              title="请选择日期"
              v-model="defaultTime"
              :min-date="minStartTime"
              @confirm="onConfirmTime"
              @cancel="showTime = false"
            />
          </van-popup>
          <van-field
            readonly
            required
            v-model="viewData.EndDate"
            name="EndDate"
            label="加班结束时间"
            placeholder="加班结束时间"
            :rules="[{ required: true, message: '请选择加班结束时间' }]"
            @click="showTime2 = true"
          />
          <van-popup v-model="showTime2" position="bottom">
            <van-datetime-picker
              type="datetime"
              title="请选择日期"
              v-model="defaultTime2"
              :min-date="minDate"
              @confirm="onConfirmTime2"
              @cancel="showTime2 = false"
            />
          </van-popup>
          <van-field
            required
            type="number"
            v-model="viewData.OverTimeDays"
            name="OverTimeDays"
            label="加班天数"
            placeholder="加班天数"
            :rules="[{ required: true, message: '请填写加班天数' }]"
          />
          <van-field
            readonly
            required
            v-model="viewData.FlowIDName"
            name="FlowIDName"
            label="审核流程"
            placeholder="审核流程"
            :rules="[{ required: true, message: '请选择审核流程' }]"
            @click="showPicker2 = true"
          />
          <van-popup v-model="showPicker2" position="bottom">
            <van-picker
              show-toolbar
              value-key="Name"
              :columns="auditFlowList"
              @confirm="onConfirm2"
              @cancel="showPicker2 = false"
            />
          </van-popup>
          <van-field
            required
            v-model="viewData.OverTimeDesc"
            name="OverTimeDesc"
            rows="2"
            label="加班事由"
            type="textarea"
            placeholder="请输入加班事由"
            :rules="[{ required: true, message: '请输入加班事由' }]"
          />
          <van-field name="AccessoryPostList" label="附件">
            <template #input>
              <van-uploader
                v-model="viewData.AccessoryPostList"
                :deletable="true"
                :preview-full-image="false"
                accept="image/*"
                :before-read="beforeRead"
                @click-preview="review"
              />
            </template>
          </van-field>
        </div>
        <van-button
          :loading="loading"
          block
          color="#47AFA7"
          type="info"
          native-type="submit"
          :disabled="disabled"
          >提交</van-button
        >
      </van-form>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Form,
  Field,
  Uploader,
  Popup,
  Picker,
  DatetimePicker,
  Toast
} from "vant";
export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,
    [Toast.name]: Toast
  },
  computed: {
    minDate: function() {
      let date = this.defaultTime;
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes() + 1
      );
    }
  },
  data() {
    return {
      id: null,
      viewData: {
        AccessoryPostList: []
      },
      leaveTypeList: [],
      auditFlowList: [],
      showPicker: false,
      showPicker2: false,
      showTime: false,
      showTime2: false,
      loading: false,
      defaultTime: new Date(),
      defaultTime2: new Date(),
      minStartTime: new Date(),
      disabled: false
    };
  },
  mounted() {
    this.getAuditFlow();
  },
  methods: {
    getAuditFlow() {
      this.auditFlowList = [];
      this.viewData.FlowIDName = "";
      this.$axios.get("/api/AuditFlow/List?limit=99&auditType=10").then(res => {
        if (res.code === 200) {
          this.auditFlowList = res.data;
        }
      });
    },
    onConfirm(value) {
      this.viewData.LeaveTypeName = value.EnumName;
      this.viewData.LeaveType = value.EnumValue;
      this.showPicker = false;
    },
    onConfirm2(value) {
      this.viewData.FlowIDName = value.Name;
      this.viewData.FlowID = value.ID;
      this.showPicker2 = false;
    },
    onConfirmTime(value) {
      this.viewData.BeginDate = new Date(value).format("yyyy-MM-dd hh:mm");
      this.showTime = false;
      // 计算总时长
    },
    onConfirmTime2(value) {
      this.viewData.EndDate = new Date(value).format("yyyy-MM-dd hh:mm");
      this.showTime2 = false;
      // 计算总时长
    },
    beforeRead(file) {
      if (file.size / 1024 > 1025) {
        this.$compress.photoCompress(
          file,
          {
            quality: 0.2
          },
          base64Codes => {
            file = this.$compress.dataURLtoFile(base64Codes, file.name);
            Toast.loading({
              message: "加载中...",
              duration: 0
            });
            let formData = new FormData();
            formData.append("LastFile", file);
            formData.append("FileName", file.name);
            this.$axios.upload("/api/File/UploadFile", formData).then(res => {
              Toast.clear();
              if (res.code === 200) {
                this.viewData.AccessoryPostList.push({
                  name: file.name,
                  osskey: res.data.osskey,
                  uploadUrl: res.data.uploadUrl,
                  url: res.data.url
                });
                return true;
              } else {
                this.$toast.fail(res.msg || "操作失败");
                return false;
              }
            });
          }
        );
      } else {
        Toast.loading({
          message: "加载中...",
          duration: 0
        });
        let formData = new FormData();
        formData.append("LastFile", file);
        formData.append("FileName", file.name);
        this.$axios.upload("/api/File/UploadFile", formData).then(res => {
          Toast.clear();
          if (res.code === 200) {
            this.viewData.AccessoryPostList.push({
              name: file.name,
              osskey: res.data.osskey,
              uploadUrl: res.data.uploadUrl,
              url: res.data.url
            });
            return true;
          } else {
            this.$toast.fail(res.msg || "操作失败");
            return false;
          }
        });
      }
    },
    review(file) {
      if (file.url) {
        window.open(file.url);
      }
    },
    save() {
      this.disabled = true;
      let that = this;
      this.$axios.post("/api/OverTime/Add", this.viewData).then(res => {
        if (res.code === 200) {
          this.$toast.success(res.msg || "操作成功");
          // setTimeout(() => {
          //   that.$router.replace({
          //     path: 'LeaveTeacher',
          //     query: {
          //       active: 0
          //     }
          //   })
          // }, 1000)
          this.disabled = false;
          this.$emit("choose");
        } else {
          this.disabled = false;
          this.$toast.fail(res.msg || "操作失败");
        }
      });
    }
  }
};
</script>
<style lang="less" scoped></style>
